<template>
	<div class='container'>
		<div class="title">
      <span class="inner_title">离职记录</span>
    </div>
		<div class="top-box">
      <div class="top-one">
				<span class="labelText" type="info">姓名：</span>
				<el-input class="rantHouseName" v-model="user_name" placeholder="请输入"></el-input>
			</div>
      <div class="top-one">
				<span class="labelText" type="info">手机号：</span>
				<el-input class="rantHouseName" v-model="mobile" placeholder="请输入"></el-input>
			</div>
      <div class="top-one">
				<span class="labelText" type="info">部门：</span>
				<el-select class="rantHouseName" v-model="department_id"  placeholder="请选择">
					<el-option v-for="item in departmentList" :key="item.id" :label="item.title" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="top-two">
				<el-button type="primary" @click="search">查询</el-button>
        <el-button type="danger" @click="removeSearch">清空</el-button>
			</div>
		</div>
    <div class="cont_container">
      <template>
        <el-table class="table" v-loading="loading"  element-loading-spinner="el-icon-loading"
          :data="tableData" :header-cell-style="TableTitleBackColor"
          :row-style="tableCss" :highlight-current-row="true" :cell-style="cellStyle">
          <el-table-column  label="序号" type="index" width="150">
          </el-table-column>
          <el-table-column prop="department_title" label="部门名称">
          </el-table-column>
          <el-table-column prop="user_name" label="员工名称">
          </el-table-column>
          <el-table-column prop="mobile" label="手机号">
          </el-table-column>
          <el-table-column prop="position_title" label="岗位名称">
          </el-table-column>
          <el-table-column prop="entry_date" label="入职日期">
          </el-table-column>
          <el-table-column prop="entry_date" label="离职日期">
          </el-table-column>
        </el-table>
      </template>
    </div>
		<el-pagination style="width: 100%;text-align: center;" background layout="prev, pager, next" :page-count="last_page"
		 :current-page="page" @current-change="pageChange">
		</el-pagination>
	</div>
</template>
<script>
	import tableCss from '../../../style/tableCss.js';
  import {getStaffListFn,getDepartmentListFn,deletePostFn} from '@/api/department.js'
	export default {
		props: [],
		data() {
			return {
				loading: false,
				tableData: [],
				page: 1,
				page_size: 10,
				last_page: 0,

        user_name:'',
        mobile:'',
        departmentList:[],
        department_id:""
			}
		},
    
		computed: {
			// ...mapState(['loadingColor', 'loadingText'])
		},
    mounted() {
			this.getStaffList();
		},
		created() {
      this.getDepartmentList()
    },
    
		methods: {
      //获取部门列表
      getDepartmentList() {
				this.loading = true;
        console.log(localStorage.getItem('token1'))
        let params = {
          api_token: localStorage.getItem('token1'),
					page: 1,
					page_size: 9999,
        }
				getDepartmentListFn(params).then(res => {
					this.departmentList = res.datas.data;
				})
			},
      //离职
      leavePost(){
        let params = {
          api_token: localStorage.getItem('token1'),
          id:row.id
        }
        this.$confirm("提交成功", "提交成功", {
          confirmButtonText: "确定",
          callback: () => {
            deletePostFn(params).then(res => {
              this.getStaffList()
            })
          }
        });
      },
      //获取员工列表
			getStaffList() {
				this.loading = true;
        console.log(localStorage.getItem('token1'))
        let params = {
          api_token: localStorage.getItem('token1'),
					page: this.page,
					page_size: this.page_size,
          is_leave:1,
          user_name:this.user_name,
          mobile:this.mobile,
          department_id:this.department_id
        }
				getStaffListFn(params).then(res => {
					this.tableData = res.datas.data;
					this.last_page = res.meta.last_page;
					this.loading = false;
				})
			},
			//搜索
			search() {
				this.getStaffList()
			},
      //清空搜索条件
      removeSearch(){
        this.user_name = ''
        this.mobile = ''
        this.department_id = ''
      },
			//编辑
			goToEditPage(row) {
				this.$router.push({
					path: '/addPost',
					query: {
						id: row.id,
						page: this.page,
						last_page: this.last_page,
					}
				});
			},
			//页数改变
			pageChange(page) {
				this.page = page;
				this.getStaffList();
			},
			//表头样式
			TableTitleBackColor() {
				return tableCss.tableTitleBackColor();
			},
			//表格样式
			tableCss() {
				return tableCss.tableCss();
			},
			//单元格样式
			cellStyle() {
				return tableCss.cellStyle();
			},
		},
		activated() {

		}
	}
</script>
<style scoped>
	@import url("../../../style/title.css");
</style>
